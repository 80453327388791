import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import './index.css';

// pages for this product
import Pools from "views/pools.js";
import Farms from "views/farms";
import { ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <BrowserRouter basename="/">
  <ToastContainer/>
    <Switch>
      <Route path="/Pools" component={Pools} />
      <Route path="/farms" component={Farms} />
      <Route path="/" component={Farms} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

