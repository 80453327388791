import React, { useEffect ,useRef} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";


// core components
import Header from "components/Header/Header.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styled from "styled-components";
import Countdown from "react-countdown";
import moment from "moment";
import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Tooltip, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import axios from "axios"
import { HelpOutline, ArrowDropDown, Launch } from '@material-ui/icons';
import icoabi from "../ABI/ICOABI.json"
import icoabi2 from "../ABI/ICO2ABI.json";
import stackabi from "../ABI/STACKABI.json"
import z_oroabi from "../ABI/Z_OROABI.json"
import variableabi from "../ABI/VARIABLEABI.json"
import operonstackabi from "../ABI/OPERONSTACKINGABI.json";
import {
  getCurAddr,
} from '../actions/users';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import config from '../config/config';
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;
const useStyles = makeStyles(styles);
var mynetwork = config.NETWORKVERSION
const dashboardRoutes = [];
const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #24272c;
    font-size: 42px;
    background-color: #383b40;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > .minus {
    display: none;
    color: #24272c;
    font-size: 42px;
    background-color: #383b40;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function LandingPage(props) {
  const classes = useStyles();
  const timerRef = useRef(null);
  
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#646e88',
    maxWidth: 220,
    fontSize: "13px",
    fontFamily: "'Montserrat', sans-serif",
    lineHeight: "20px",
    border: '1px solid #fff',
    boxShadow: '-4px 5px 10px 2px rgb(0 0 0 / 20%)'
  },
}))(Tooltip);
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span></span>
        } else {
            return <span>{hours}h {minutes}m {seconds}s</span>;
        }
    };

  const { ...rest } = props;
  const [accounts, setAccounts]     = React.useState("");
  const [harvestprogress,setharvestprogress] = React.useState(false)
  const [apy,setapy] = React.useState(0)
  const [balance,setbalance] = React.useState(0)
  const [amount,setamount] = React.useState(0)
  const [earnamt,setearnamt] = React.useState(0)
  const [inprogress,setinprogress] = React.useState(false)
  const [myallowance,setallowance] = React.useState(0)
  const [stakedamt, setstakedamt] = React.useState(0)
  const [withamount,setwithamount] = React.useState(0)
  const [approveprogress,setapproveprogress] = React.useState(false)
  const [unstakestatus,setunstakestatus] = React.useState(false)
  const [liquitity,setliquitity] = React.useState(0)
  const [Oroprice,setOroprice] = React.useState(0)
  const [timer,settimer] = React.useState(0)
  const [poolstakedamt,setpoolstakedamt] =  React.useState(0)
  const [tvlvalue,settvlvalue] = React.useState(0)


   useEffect(() => {
    getinit();
    const interval = setInterval(async() => {
      await gettvlvalue();

      if(localStorage.getItem("address") && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!=""){
        getharvest(localStorage.getItem("address"))
        console.log('This will run every second!');
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

 
   async function gettvlvalue(){
      const resp = await axios.post(
      config.baseurl + `/tvlvalue`,{page:1}
    );
      console.log(resp,"=================tvlvalue")
      if(resp && resp.data && resp.data.data && resp.data.data[0] && resp.data.data[0].totalamount){
        settvlvalue(resp.data.data[0].totalamount)
      }else{
        settvlvalue(0)
      }
      let web3 = new Web3(window.ethereum)
      let lpContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT);
      let balance = await lpContract.methods.balanceOf(config.STACKINGCONTRACT).call();
      // console.log(balance,"=========balance")
      if(balance>0){
        let value = balance/1e18;
        settvlvalue(value)
      }else{
        settvlvalue(0)
      }
  }

  

  async function getinit(){
    getapy()
    getliqiuity()
    getpoolliqiuity()
    try{
      if(localStorage.getItem("orodsfbjyddjsbyy")){
         var curAddr = await getCurAddr();
         if(curAddr == localStorage.getItem("address")){
          setAccounts(curAddr)
          localStorage.setItem("orodsfbjyddjsbyy",'yes');
          localStorage.setItem("address",curAddr);
          getharvest(curAddr);
         }else{
          localStorage.clear();
          window.location.reload()
         }
      }
      }catch(err){
        
      }
    }

  async function getliqiuity(){
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
          var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT);
          var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
          var variable1 = await icoContract.methods.balanceOf(config.STACKINGCONTRACT).call();
          var variable2 = await icoContract.methods.totalSupply().call();
          var variable3 = parseFloat(variable1)/parseFloat(variable2);
          var variablecontract = await icoContract.methods.token0().call();
          var newvarcontract = new web3.eth.Contract(variableabi, variablecontract);
          var variablebalance = await newvarcontract.methods.balanceOf(config.ICOCONTRACT).call();
          var quoteTokenBalance = parseFloat(variablebalance)/1000000000000000000; 
          var variable4 = parseFloat(variable3)*parseFloat(quoteTokenBalance)
          var finalliquity = parseFloat(variable4)*2
          setliquitity(finalliquity)
        }
      }catch(err){
        console.log(err,"======================err4")
      }
    }
  }

  async function getpoolliqiuity(){
      axios.get('https://api.pancakeswap.info/api/v2/tokens/0x11d1ac5ec23e3a193e8a491a198f5fc9ee715839')
      .then(async(response)=>{
        console.log(response.data.data.price,"========response.data.data.price")
        if(response && response.data && response.data.data && response.data.data.price){
          setOroprice(response.data.data.price)
        }
      })
  }

  async function getapy(){
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
          var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT);
          var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
          console.log(stackcontract,"============stackcontrct")
          var apyc =  await stackcontract.methods.OperonAPYLP().call()
          console.log(apyc,"===================apycapycapycapyc")
          setapy(apyc/1000000000000)
        }
      }catch(err){
        console.log(err,"======================err4")
      }
    }
  }

  async function connectMetamask(){
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
         if(window.web3.currentProvider.networkVersion == mynetwork){
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setAccounts(result[0]);
            localStorage.setItem("orodsfbjyddjsbyy",'yes');
            localStorage.setItem("address",result[0]);
            getharvest(result[0])

            //second phase
            // setAccounts2(result[0]);
            // getharvest2(result[0])
            window.location.reload()
          })
        }
        }else {
              toast.warning("Please Connect to BNB Network", toasterOption);
        }
        })
        }else{
             toast.warning("Please Add Metamask External", toasterOption);
        }
      }catch(err){}
     }else{
             toast.warning("Please Add Metamask External", toasterOption);
     }
  }

  async function deposit(){
    setinprogress(true)
    if(amount!=null && amount!=undefined && amount!="" && parseFloat(amount)>0 ){
      if(parseFloat(amount)<=parseFloat(balance)){
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
         if(window.web3.currentProvider.networkVersion == mynetwork){
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setAccounts(result[0]);
            localStorage.setItem("orodsfbjyddjsbyy",'yes');
            localStorage.setItem("address",result[0]);
            var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT);
            var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
            var Tamount = await web3.utils.toWei((amount).toString());
             Tamount = await convert(Tamount)
              await stackcontract.methods.deposit(0,Tamount).send({from: result[0]})
             .then(async (deposituccess) => {
              toast.success("Deposited Successfully", toasterOption);
              window.location.reload();
             })
             .catch((errors) => {
              setinprogress(false)
              toast.warning("Failed Tryagain Later", toasterOption);
             })
          })
        }else {
              setinprogress(false)
              toast.warning("Please Connect to BNB Network", toasterOption);
        }
        }else {
              setinprogress(false)
              toast.warning("Please Connect to BNB Network", toasterOption);
        }
        })
        }else{
             setinprogress(false)
             toast.warning("Please Add Metamask External", toasterOption);
        }
      }catch(err){
        setinprogress(false)
        toast.warning("Failed Tryagain Later", toasterOption);
      }
     }else{ 
             setinprogress(false)
             toast.warning("Please Add Metamask External", toasterOption);
     }
    }else{
             setinprogress(false)
             toast.warning("Insufficient Amount", toasterOption);
     }
   }else{
             setinprogress(false)
             toast.warning("Please Enter Valid Amount", toasterOption);
     }
  }

  function convert(n){
      var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead,decimal,pow] = n.toString()
       .replace(/^-/,"")
       .replace(/^([0-9]+)(e.*)/,"$1.$2")
       .split(/e|\./);
       return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
    }

  async function getharvest(myadd){
    var web3 = new Web3(window.ethereum);
    if (typeof web3 !== 'undefined') {
      try{
          var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT);
          var bal =  await icoContract.methods.balanceOf(myadd).call()
          setbalance((parseFloat(bal)/1000000000000000000).toFixed(4))
          var allowance = await icoContract.methods.allowance(myadd,config.STACKINGCONTRACT).call()
          setallowance(parseFloat(allowance)/1000000000000000000)
          var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
          var pendingamt = await stackcontract.methods.pendingOperon(0,myadd).call();
          setearnamt((parseFloat(pendingamt)/1000000000000000000).toFixed(8))
          console.log(pendingamt,"==============pendingamt")
          var interval = await stackcontract.methods.timeInterval().call()
          var userdet = await stackcontract.methods.userInfo(0,myadd).call();
          console.log(userdet,"===================userdet")
          var resultdata = await JSON.parse(JSON.stringify(userdet))
          if(resultdata && resultdata.amount && resultdata.amount!=null && resultdata.amount!=undefined && resultdata.amount!="" && parseFloat(resultdata.amount)>0){
            setstakedamt((parseFloat(resultdata.amount)/1000000000000000000).toFixed(4))
          }else{
            setstakedamt(0)
          }
          var userdet1 = await stackcontract.methods.userInfo(1,myadd).call();
          var resultdata1 = await JSON.parse(JSON.stringify(userdet1))
          if(resultdata1 && resultdata1.amount && resultdata1.amount!=null && resultdata1.amount!=undefined && resultdata1.amount!="" && parseFloat(resultdata1.amount)>0){
            setpoolstakedamt((parseFloat(resultdata1.amount)/1000000000000000000).toFixed(4))
          }else{
            setpoolstakedamt(0)
          }

        }catch(err){
          console.log(err,"==============errorsss")
        }
    }else{
         toast.warning("Please Add Metamask External", toasterOption);
    }
  }

  async function proceedharvest(){
    setharvestprogress(true)
     if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
         if(window.web3.currentProvider.networkVersion == mynetwork){
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setAccounts(result[0]);
            localStorage.setItem("orodsfbjyddjsbyy",'yes');
            localStorage.setItem("address",result[0]);
            var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT);
            var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
             await stackcontract.methods.Harvest(0).send({from: result[0]})
             .then(async (successharvest) => {
              setharvestprogress(false)
               toast.success("Harvested Successfully", toasterOption);
               window.location.reload()
             })
             .catch((errors) => {
              setharvestprogress(false)
              toast.warning("Failed TryAgain Later", toasterOption);
             })
            
          })
        }else{
          setharvestprogress(false)
          toast.warning("Please Add Metamask External", toasterOption);
        }
        }else {
            setharvestprogress(false)
            toast.warning("Please Connect to BNB Network", toasterOption);
        }
        })
        }else{
           setharvestprogress(false)
           toast.warning("Please Add Metamask External", toasterOption);
        }
      }catch(err){
        setharvestprogress(false)
        toast.warning("Please Add Metamask External", toasterOption);
      }
     }else{
          setharvestprogress(false)
          toast.warning("Please Add Metamask External", toasterOption);
     }
  }

  const amountchange = (e) => {
    console.log(e.target.value,"=========amountchange")
    setamount(e.target.value)
  }

  async function setmax(){
    setamount(balance)
  }

  async function approvefunction(){
    setapproveprogress(true)
     if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
         if(window.web3.currentProvider.networkVersion == mynetwork){
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setAccounts(result[0]);
            localStorage.setItem("orodsfbjyddjsbyy",'yes');
            localStorage.setItem("address",result[0]);
            var Tamount = await web3.utils.toWei((1000000000000000000).toString());
             Tamount = await convert(Tamount)
            var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT);
            var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
             await icoContract.methods.approve(config.STACKINGCONTRACT,Tamount).send({from: result[0]})
             .then(async (approvesuccess) => {
              setapproveprogress(false)
              toast.success("Approved Successfully", toasterOption);
              window.location.reload()
              })
             .catch((error) => {
              setapproveprogress(false)
              toast.warning("Failed Tryagain Later", toasterOption);
             })
            
          })
        }else{
          setapproveprogress(false)
          toast.warning("Please Connect to BNB Network", toasterOption);
       
        }
        }else {
            setapproveprogress(false)
            toast.warning("Please Connect to BNB Network", toasterOption);
        }
        })
        }else{
           setapproveprogress(false)
           toast.warning("Please Add Metamask External", toasterOption);
        }
      }catch(err){
        setapproveprogress(false)
        toast.warning("Failed Try Again Later", toasterOption);
      }
     }else{
          setapproveprogress(false)
          toast.warning("Please Add Metamask External", toasterOption);
     }
  }

  async function setwithmax(){
    setwithamount(stakedamt)
  }

  const withamountchange = (e) => {
    setwithamount(e.target.value)
  }

  async function withdraw(){
    setinprogress(true)
    if(withamount && withamount!="" && withamount!=null && withamount!=undefined && parseFloat(withamount)>0){
      if(parseFloat(withamount)<=parseFloat(stakedamt)){
     if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
         if(window.web3.currentProvider.networkVersion == mynetwork){
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setAccounts(result[0]);
            localStorage.setItem("orodsfbjyddjsbyy",'yes');
            localStorage.setItem("address",result[0]);
            var withdrawamt = await web3.utils.toWei((withamount).toString());
             withdrawamt = await convert(withdrawamt)
            var icoContract = new web3.eth.Contract(icoabi, config.ICOCONTRACT);
            var stackcontract = new web3.eth.Contract(stackabi, config.STACKINGCONTRACT);
             await stackcontract.methods.withdraw(0,withdrawamt).send({from: result[0]})
             .then(async (success) => {
               toast.success("Withdraw Successfully", toasterOption);
               window.location.reload();
             })
             .catch((errors) => {
              setinprogress(false)
              toast.warning("Failed Try again Later", toasterOption);

             })
            
          })
        }else {
          setinprogress(false)
            toast.warning("Please Connect to BNB Network", toasterOption);
        }
        }else {
          setinprogress(false)
            toast.warning("Please Connect to BNB Network", toasterOption);
        }
        })
        }else{
          setinprogress(false)
           toast.warning("Please Add Metamask External", toasterOption);
        }
      }catch(err){
       setinprogress(false)
       toast.warning("Failed Try again Later", toasterOption);
      }
     }else{
      setinprogress(false)
          toast.warning("Please Add Metamask External", toasterOption);
     }
     }else{
      setinprogress(false)
    toast.warning("Insufficient Balance", toasterOption);
   }
   }else{
    setinprogress(false)
    toast.warning("Please Enter Valid Amount", toasterOption);
   }
  }

  window.addEventListener('load', (event) => {
    event.preventDefault();
    console.log('addEventListener',event);
    if(window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        window.location.reload(false);
        if(timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          connectMetamask();
        }, 1000);
      })

      window.ethereum.on('networkChanged', function (networkId) {
        if(networkId == mynetwork){
          window.location.reload(false);
          if(timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(() => {
            connectMetamask();
          }, 1000);
        }
        else {
          localStorage.clear()
          toast.warning("Please Choose Mainnet", toasterOption);
        }
      })
    }
  })


  return (
    <div>      
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />    
      <ScrollToTopOnMount/>
      <div className="">
        <div className={classes.container}>
          
        </div>
      </div>

      <div className="main padding_top_g">

      <div className="inner_header">
        <div className={classes.container}>          

          <GridContainer className="mt-5 align-items-center justify-content-center">
           <GridItem md={4} sm={12} lg={5} data-aos="fade-up" data-aos-duration="2000">             
              <div className="grid_view_single" data-aos="fade-up" data-aos-duration="2000">
                <div className="WoodBgTop"></div>
                <div className="WoodBgMidd">
                  <h4 className="text-center">Total Value Locked {parseFloat(tvlvalue).toFixed(2)} in ORO-BNB LP </h4>
                  <div className="grid_view_single_first">
                    <div>
                      <h2>ORO BNB LP</h2>
                      {/*<p>Automatic restaking</p>*/}
                    </div>
                    <div className="icon_section_new">
                    <div className="primer_img">
                    <img src={require("../assets/images/fav.png")} alt="Icon" />
                    </div>
                    
                    </div>
                  </div>
                  <hr />
                  <div className="grid_view_single_second">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p>APY</p>
                      <div className="d-flex align-items-center"><p>{apy}%</p></div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p>Earned</p>
                        <h4>{earnamt}</h4>
                      </div>
                        {earnamt>0?<Button className="harvest_btn disabled_btn" onClick={harvestprogress?"":()=>proceedharvest()}>{harvestprogress?"In Progress":"Harvest"}</Button>
                         : <Button className="harvest_btn disabled_btn" disabled >Harvest</Button>}
                    </div>
                    <div className="count_add_btn d-flex align-items-center justify-content-between mb-2">
                      <label className="mb-2">Start Earning</label>
                    </div>
                    <div className="count_add_btn d-flex align-items-center justify-content-between mb-2">
                    <div>Staked Amount : {stakedamt}</div>
                    {parseFloat(stakedamt)>0 && parseFloat(myallowance)>0?
                    <div className="stake_btn_grps">
                    <Button className="primary_btn " data-toggle="modal" data-target="#withdraw_modal">-</Button>
                    <Button className="primary_btn " data-toggle="modal" data-target="#Tokens_modal">+</Button>
                    </div>
                    :
                    ""
                    }
                    </div>
                    {accounts!="" ?
                    parseFloat(myallowance)<=0?
                    <Button className="primary_btn blue_btn" onClick={approveprogress?"":()=>approvefunction()}>{approveprogress?"In Progress": "Approve Contract"}</Button>
                    :
                    parseFloat(stakedamt)>0?
                    ""
                    :
                    <Button className="primary_btn blue_btn"  data-toggle="modal" data-target="#Tokens_modal">Stake</Button>
                    :
                    <Button className="primary_btn blue_btn" onClick={()=>connectMetamask()}>Connect Wallet</Button>
                  }
                  </div>
                  <hr />
                  <div className="grid_view_single_third">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                      </div>
                      <a className="accordian_link" data-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample">
                        Details
                      </a>
                    </div>
                    <div className="collapse" id="collapseExample1">
                      <div>
                      <div className="d-flex justify-content-between align-items-center">
                          <h3>Total Liquidity:</h3>
                          <h4>$ {liquitity.toFixed()}</h4>
                        </div>
                        <div className="d-flex justify-content-between align-items-start">
                          <h3></h3>
                          <div className="text-right">
                            <p><a href={config.CONTRACTURL+config.ICOCONTRACT} target="_blank">View Contract <i className="bi bi-box-arrow-up-right"></i></a></p>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="WoodBgTop"></div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

        <FooterHome />
           {/*  ROI Modal */}
      <div className="modal fade primary_modal toledbn" data-backdrop="static" id="Tokens_modal" tabIndex="-1" role="dialog" aria-labelledby="roi_modal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header pt-1 mb-3 position">
              <h5 className="modal-title" id="Tokens_modal">Deposit</h5>
              <button type="button" className="close close_new" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
             <div className="input_panel">
                  <div className="d-flex justify-content-between align-items-center">
                    <label>Bal: {balance}</label>
                    </div>  
                    <div className="d-flex justify-content-between align-items-center home_inp_panel">
                      <input type="number"  className="custom_inp" min="0" onChange={amountchange} value={amount}/>
                      <Button className="harvest_btn" onClick={setmax}>Max</Button>
                    </div>                  
             </div>
             <div className="mt-3 d-flex justify-content-between align-items-center">
               <Button className="primary_btn blue_btn mr-3"  data-dismiss="modal" aria-label="Close">Cancel</Button> 
               <Button className="primary_btn blue_btn" onClick={accounts!=""?()=>deposit():()=>connectMetamask()}>{inprogress ? "In Progress" : "Confirm"}</Button>                       
             </div>
            
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade primary_modal toledbn" data-backdrop="static" id="withdraw_modal" tabIndex="-1" role="dialog" aria-labelledby="roi_modal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header pt-1 mb-3 position">
              <h5 className="modal-title" id="withdraw_modal">Withdraw</h5>
              <button type="button" className="close close_new" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
             <div className="input_panel">
                  <div className="d-flex justify-content-between align-items-center">
                    <label>Bal: {stakedamt}</label>
                    </div>  
                    <div className="d-flex justify-content-between align-items-center home_inp_panel">
                      <input type="number"  className="custom_inp"  onChange={withamountchange} value={withamount} />
                      <Button className="harvest_btn" onClick={setwithmax}>Max</Button>
                    </div>                  
             </div>
             <div className="mt-3 d-flex justify-content-between align-items-center">
               <Button className="primary_btn blue_btn mr-3"  data-dismiss="modal" aria-label="Close">Cancel</Button> 
               <Button className="primary_btn blue_btn" onClick={accounts!=""?()=>withdraw():()=>connectMetamask()}>{inprogress ? "In Progress" : "Confirm"}</Button>                       
             </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade primary_modal toledbn" data-backdrop="static" id="Connet_w" tabIndex="-1" role="dialog" aria-labelledby="roi_modal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header pt-1 mb-3 position">
              <h5 className="modal-title" id="Connet_w">Connect to a wallet</h5>
              <button type="button" className="close close_new" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="wallet_panel_div">
                <div className="wallet_panel">
                  <h2>Metamask</h2>
                  <img src={require("../assets/images/metamask_icon.png")} className="mr-2" />
                  </div>                    
            </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    
        
  );
}
