import config from '../config/config';
import Web3 from 'web3';
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

export const getCurAddr = async () => {
  var currAddr = '';
  if(localStorage.getItem("orodsfbjyddjsbyy")){
    console.log(localStorage.getItem("orodsfbjyddjsbyy"),"=================")
  if (window.ethereum) {
    var web3 = new Web3(window.ethereum);
    console.log(window.web3
      ,"====", window.web3.eth
      ,"====", window.web3.eth.defaultAccount)
    if(
      window.web3
      && window.web3.eth
    ) {
      console.log("==============inside")
      var accVal = await web3.eth.getAccounts();
      console.log(accVal,"===============accval")
      if(accVal[0]) {
        currAddr = accVal[0];
        return currAddr;
      }else{
        console.log("==============inelse")
     return currAddr;
     }
    }else{
      console.log("==============inelse")
    return currAddr;
  }
  }else{
    console.log("==============inelse")
  return currAddr;
 }
 }else{
  console.log("==============inelse")
  return currAddr;
}
}